module.exports = function () {
    const slidePartners = document.querySelector('.c-section_partners .glide');
    const config = {
        type: 'carousel',
        perView: 7,
        gap: 30,
        autoplay: 5000,
        breakpoints: {
            1200: {
                perView: 4,
            },
            800: {
                perView: 3,
            },
            550: {
                perView: 2,
            },
        },
    };

    if (slidePartners) {
        new Glide(slidePartners, config).mount();
    }
}
