module.exports = function() {
    const blog = document.querySelector('.c-blog');

    if (!blog) {
        return;
    }

    // Filter

    const filter = blog.querySelector('.c-filter-wrapper select');
    
    if (filter) {
        filter.addEventListener('change', (e) => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', ajaxurl, true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            const params = `action=filterBlog&categorie=${e.target.value}`;
            
            xhr.send(params);

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4 && xhr.status === 200) {
                    let url;
                    const search = blog.querySelector('#searchPost');
                    const blocBlog = blog.querySelector('.c-blog-containerFluid');

                    search.value = "";
                    blocBlog.dataset.categorie = e.target.value;
                    blocBlog.innerHTML = xhr.response;
                    
                    // local
                    // const originUrl = window.location.origin + '/payroll/blog/';
                    
                    // Pre-prod
                    const originUrl = window.location.origin + '/blog/';
                    
                    if (e.target.value === "all-categories") {
                        url = originUrl;
                    } else {
                        url = originUrl + e.target.value + '/';
                    }
                    
                    history.pushState('', '', url);
                }
            };
        })
    }

    // Search

    const search = blog.querySelector('#searchPost');

    if (search) {
        search.addEventListener('input', (e) => {
            const wordSearch = e.target.value;
            const blocBlog = blog.querySelector('.c-blog-containerFluid');
            let categorie = "";

            if (blocBlog.dataset.categorie) {
                categorie = blocBlog.dataset.categorie;
            }
            
            const xhr = new XMLHttpRequest();
            xhr.open('POST', ajaxurl, true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            const params = `action=searchBlog&search=${wordSearch}&categorie=${categorie}`;
            
            xhr.send(params);

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4 && xhr.status === 200) {
                    blocBlog.innerHTML = xhr.response;
                }
            };
        })
    }
}