module.exports = function() {
    const tabs = document.querySelectorAll(
        '.c-section_tabs .tabs-nav .title'
    );

    if (tabs) {
        const contents = document.querySelectorAll(
            '.c-section_tabs .content-tab'
        );

        tabs.forEach((tab, i) => {
            tab.addEventListener('click', function(){
                tabs.forEach(el => {
                    el.classList.remove('title--active');
                });
                tab.classList.add('title--active');

                contents.forEach((content, j) => {
                    if (j == i) {
                        content.classList.add('js-is-open');
                    } else {
                        content.classList.remove('js-is-open');
                    }
                });
            });
        });

        const heads = document.querySelectorAll('.item-head');
        const respContents = document.querySelectorAll('.item-content');

        heads.forEach((head, k) => {
            head.addEventListener('click', function(){

                respContents.forEach((respContent, l) => {
                    if (l == k) {
                        respContent.classList.add('js-is-open');
                    } else {
                        respContent.classList.remove('js-is-open');
                    }
                });
            });
        });
    }
}
