module.exports = function () {
  /*
    * Fonctionnement du système d'avis/review sur les page par defaut et single article
  */
  const starsContainer = document.querySelector('.star-rating-container');
  const stars = document.querySelectorAll('.star-rating__star');
  const alreadyMessage = document.querySelector('.section-review .alreadyReview');
  const sendMessage = document.querySelector('.section-review .sendReview');
  const notationContainer = document.querySelector('.section-review__notation');
  
  if (stars) {
    stars.forEach((star) => {
      const starValue = star.dataset.star;

      star.addEventListener('click', () => {
        // Reset stars
        resetStars(stars);

        // Garder dans une data la note séléctionnée
        starsContainer.setAttribute('data-review', starValue);

        // Faire en sorte que toutes les étoiles précédants celle cliquée soient jaune
        for(let i = 0; i < starValue; i++) {
          stars[i].classList.add('is-selected')
        }
      });
    });
  }

  /*
    * Reset compteur étoile
    * Pour qu'au clic on reparte de zéro
  */
  function resetStars() {
    stars.forEach((star) => {
      star.classList.remove('is-selected')
    });
  }
  
  /*
    * Click sur le bouton Noter
    * Enregistrement de la note dans un champ caché dans le back de la page
    * Si le user a déjà noter cette page auparavant (cookie) = message déjà une note
    * Sinon enregisrement de la note dans back = message merci pour votre note 
  */
  const buttonRating = document.querySelector('.c-button-rating');
  if(buttonRating) {
    buttonRating.addEventListener('click', () => {
      // Get cookie review
      const cookieValue = getCookie('reviewCookie');
      const postId = buttonRating.dataset.id;
      const rating = starsContainer.dataset.review;

      // check if cookie review exist && if the current user already send a review for this post
      if (
        cookieValue
        && cookieValue.includes(postId)
      ) {
        // message => avis déjà déposé
        notationContainer.classList.add('js-is-hidden');
        alreadyMessage.classList.add('js-is-visible');
      } else {
        const reviewCookie = (cookieValue) || [];
        reviewCookie.push(postId);
        // Si non :
        // mettre à jour le json du champ avis de la page en ajoutant la note
        // si la note est supérieure à 2 alors on enregistre la note dans la note globale de la page
        if (rating > 2) {
          // faire call ajax 
          const xhr = new XMLHttpRequest();
          xhr.open('POST', ajaxurl, true);
          xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
          const params = `action=save_rating&rating=${rating}&post=${postId}`;
        
          xhr.send(params);

          xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
              console.log(xhr.response);
              location.reload();
            }
          };
        }
        // ajout du cookie pour dire qu'il a laissé un avis sur cette page
        setCookie('reviewCookie', reviewCookie)
        // message => votre avis a bien été pris en compte
        notationContainer.classList.add('js-is-hidden');
        sendMessage.classList.add('js-is-visible');
      }
    });
  }

  /**
   * Get the value of a cookie
   * @param  {String} name  The name of the cookie
   * @return {String}       The cookie value
   */
  function getCookie (name) {
    let value = `; ${document.cookie}`;
    let parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  /**
   * Set the value of a cookie
   * @param  {String} name  The name of the cookie
   * @return {String}       The cookie value
   */
  function setCookie (name, value) {
    document.cookie = `${name}=${value}; max-age=${60 * 60 * 24 * 7 * 52};`;
  }
};

