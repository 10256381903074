module.exports = function () {
    const slideTesti =  document.querySelector('.testimonials');
    const config = {
        type: 'slider',
        focusAt: 'center',
        perView: 1,
        autoplay: 3000,
    };


    if (slideTesti) {
        const testimonials = slideTesti.querySelectorAll('.testimonial');
        if (testimonials.length > 0) {
            new Glide(slideTesti, config).mount();
            /**
            * recalage des barres de navigatiom
            * @author Guillaume
            */
            setTimeout(function(){
                const btnWrap = slideTesti.querySelector('.glide__bullets');
                var coordY = testimonials[1].offsetHeight;

                if (window.innerWidth > 860) {
                    coordY -= 9; // ajout de padding et margin
                    const coordX = 325 + 65; // largeur image plus gap
                    btnWrap.setAttribute('style', 'transform: none;');
                    btnWrap.style.left = coordX + 'px';
                    // btnWrap.setAttribute('style', `bottom: ${coordY}px;`);
                } else {
                    coordY = document.querySelector('.glide__track').offsetHeight;
                }
                coordY += 40; // ajout de padding et margin
                btnWrap.style.top = coordY + 'px';
            }, 100);
        }
    }

    // slider bandeau partenaires
    const slidePartners =  document.querySelector('.partners');
    const configPartners = {
        type: 'carousel',
        focusAt: 'center',
        perView: 7,
        startAt: 3,
        autoplay: 3000,
    };

    // console.log(slidePartners);
    if (slidePartners) {
        const partners = slidePartners.querySelectorAll('li');
        if (partners.length > 0) {
            // console.log(partners);
            new Glide(slidePartners, configPartners).mount();
        }
    }
}
