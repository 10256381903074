module.exports = function () {
    const slideBloc =  document.querySelector('#section-clients .glide'); 
    const config = {
        type: 'carousel',
        focusAt: 'center',
        perView: 7,
        autoplay: 2000,
        breakpoints: {
            1270: {
                perView: 5
            },
            830: {
                perView: 3
            },
            650: {
                perView: 2
            }
        }
    };


    if (slideBloc) {
        new Glide(slideBloc, config).mount();
    }
}
