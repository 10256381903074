module.exports = function() {
    const menusFooter = document.querySelectorAll('.c-footer__direct__item'); 

    if (menusFooter) {
        menusFooter.forEach((el) => {
            const title = el.querySelector('.c-footer__title');
            const plus = el.querySelector('.plus');
            const direct = el.querySelector('.direct');

            title.addEventListener('click', () => {
                plus.classList.toggle('js-is-open');
                direct.classList.toggle('js-is-open');
            });
        });
    }
};
