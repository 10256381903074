module.exports = function () {

    const slideSectionCols = document.querySelector('.c-section_cols .glide');
    const config = {
        type: 'slider',
        perView: 3,
        perSwipe: 3,
        // dragThreshold: false,
        focusAt: 0,
        gap: 100,
        breakpoints: {
            1200: {
                perView: 2,
            },
            750: {
                perView: 1,
            },
        },
    };

    if (slideSectionCols) {
        if (parseInt(slideSectionCols.dataset.nbcols, 10) <= config.perView) {
            config.dragThreshold = false;
            slideSectionCols.style.cursor = "default";
        }
        const slider = new Glide(slideSectionCols, config).mount();
        launcher(slider, slideSectionCols);
        slider.on(['mount.after', 'run'], function() {
            launcher(slider, slideSectionCols);
        })
    }

    function launcher(slider, slideSectionCols) {
        const currentIndex = slider.index;

        const numberCols = slideSectionCols.dataset.nbcols;
        const pv = slider.settings.perView;

        const numberPage = Math.ceil(numberCols / pv);
        const currentPage = Math.ceil(currentIndex / pv);
        const controlWidth = 100 / numberPage;
        const controllerItem = slideSectionCols.querySelector('.controller-item');

        if (controllerItem) {
            if (pv === 1) {
                const calcul = (currentIndex === 0) ? 0 : 100 / numberPage * currentIndex;
                controllerItem.style.left = `${calcul}%`;
                controllerItem.style.width = `${controlWidth}%`;
            } else {
                const calcul = (currentIndex === 0) ? 0 : 100 / (numberPage * currentPage);
                controllerItem.style.left = `${calcul}%`;
                controllerItem.style.width = `${controlWidth}%`;
            }
        }
    }
}
