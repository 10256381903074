module.exports = function() {
  /* Animation menu fixed/sticky on scroll */
  const header = document.querySelector('#header');

  if (header) {
    const hero = document.querySelector('.home .c-hero');
    const scrollHeight = 0;

    window.addEventListener('scroll', () => {
      let windowY = window.scrollY;
      if (windowY > scrollHeight) {
        header.classList.add('js-is-fixed');
        setTimeout(() => {
          header.classList.add('js-is-animated');
        }, 200);
      } else {
        header.classList.remove('js-is-fixed');
        header.classList.remove('js-is-animated');
      }
    });

    // language switcher
    const switcher = header.querySelector('.lang-switcher');
    const submenu = header.querySelector('.lang-switcher .sub-menu');
    if (switcher) {
      // animation langage switcher
      switcher.addEventListener('click', (e) => {
        e.preventDefault()
        switcher.classList.toggle('js-is-visible')
      })

      if (submenu) {
        // Pour permettre au lien qui n'est pas current sur le langage switcher de pouvoir etre cliqué
        submenu.addEventListener('click', (e) => {
          e.stopPropagation()
        })
      }
    }

    // responsive
    const switcherRes = document.querySelector('.lang-switcher__responsive');
    const submenuRes = document.querySelector('.lang-switcher__responsive .sub-menu');
    if (switcherRes) {
      // animation langage switcher
      switcherRes.addEventListener('click', (e) => {
        e.preventDefault()
        switcherRes.classList.toggle('js-is-visible')
      })

      // Pour permettre au lien qui n'est pas current sur le langage switcher de pouvoir etre cliqué
      if (submenuRes) {
        submenuRes.addEventListener('click', (e) => {
          e.stopPropagation()
        })
      }
    }
  }
}
