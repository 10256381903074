function modalCatFormations() {
  const catFormationsButton = document.querySelector('.cat-sticky-btn');
  const catHero = document.querySelector('.formation-cat-hero');

  if (catFormationsButton && catHero) {
    const catFormationsModal = catHero.querySelector('.c-modal-container');
    const close = catFormationsModal.querySelector('.c-modal-close');
    const overlay = catFormationsModal.querySelector('.overlay');

    /*
      * OPEN MODAL
    */
    catFormationsButton.addEventListener('click', (e) => {
      e.preventDefault();
      catFormationsModal.classList.toggle('js-is-open');
    });

    /*
      * CLOSE MODAL
    */
    close.addEventListener('click', () => {
      catFormationsModal.classList.remove('js-is-open');
    });

    overlay.addEventListener('click', () => {
      catFormationsModal.classList.remove('js-is-open');
    });
  }
}

module.exports = modalCatFormations;
