module.exports = function() {
    // const commentForm = document.querySelector('.commentForm');
    
    // if (commentForm) {
    //     let isLoading = false;
    //     let error_info = "";

    //     commentForm.addEventListener('submit', (e) => {
    //         e.preventDefault();

    //         const form = e.target;
    //         const name_input = form.querySelector('#author_name');
    //         const email_input = form.querySelector('#author_email');
    //         const message_input = form.querySelector('#content');
    //         console.log(message_input.value);
            

    //         isLoading = true;
            
    //         if (!validateEmail(email_input.value)) {
    //             error_info = 'Email invalide';
    //             isLoading = false;
    //         } else if (name_input.value === '') {
    //             error_info = 'Auteur invalide';
    //             isLoading = false;
    //         } else if (message_input.value === '') {
    //             error_info = 'Message invalide';
    //             isLoading = false;
    //         } else {
    //             const commentStatus = await api.sendComment(
    //                 this.post_id,
    //                 this.author_name,
    //                 this.author_email,
    //                 this.comment_content,
    //             );
    //             this.isSend = true;
    //             if (commentStatus.error) {
    //                 this.error_info = 'error';
    //             } else {
    //                 this.error_info = this.$t('comment_send');
    //             }
    //             }
    //         }
    //     });
    // }
}

function validateEmail(author_email)
{
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return author_email.match(mailformat);
}
