module.exports = function() {
    const btns = document.querySelectorAll(
        '.c-section_testimonials .bullet'
    );

    if (btns) {
        const pics = document.querySelector('.picture');

        const contents = document.querySelectorAll('.c-section_testimonials .content');

        btns.forEach((btn, i) => {
            btn.addEventListener('click', function(){

                const x = 340 * i;
                pics.style.transform = `translateX(-${x}px)`;

                contents.forEach((content, j) => {
                    if (j !== i) {
                        content.classList.remove("animateLeft");
                        content.classList.add("no-opacity-animation");
                    } else {
                        content.classList.add("animateLeft");
                        content.classList.remove("no-opacity-animation");
                    }
                });

                btns.forEach(v => {
                    v.classList.remove('bullet--active');
                });
                btn.classList.add('bullet--active');
            });
        });
    }
}
