module.exports = function () {
  /*
    * Affichage et animation du menu ancre sur les pages par défaut
  */
  const anchors = document.querySelectorAll('.scrollAnchor');
  const anchorsLink = document.querySelectorAll('.scrollAnchor a');
  const sectionAnchors = document.querySelectorAll('.section-anchor');
  const anchorWrap = document.querySelector('.c-menu-anchor__wrap');

  if (anchors) {
    anchors.forEach((anchor) => {
      /*
        * Animate scrollTo on menu anchor item click
      */
      anchor.addEventListener('click', (event) => {
        event.preventDefault();

        // reset class current on all menu anchors item
        anchorsLink.forEach((link) => {
          link.classList.remove('js-is-current');
        });

        // add class current to menu anchor item clicked
        event.target.classList.add('js-is-current');

        // get href and get which section has this id
        const target = event.target.getAttribute('href');
        const contentIn = event.target.getAttribute('data-more');
        var top = document.querySelector(target).offsetTop;
        var targetTop = top + parseInt(contentIn, 10);
        const left = event.target.offsetLeft;
        const startPosition = window.pageYOffset;
        const startXPosition = window.pageXOffset;

        // adaptation de la fonction a menu milieu de page
        const startDiv = document.querySelector('.pricing__flexibles');
        if (startDiv) {
          const bodyRect = document.body.getBoundingClientRect();
          const targetRect = document.querySelector(target).getBoundingClientRect();
          top = targetRect.top - bodyRect.top;
          targetTop = top - 30;
        }


        /* Animation scroll */
        const anchorWrap = document.querySelector('.c-menu-anchor__wrap');
        let start = null;

        function step(timestamp) {
          if (!start) start = timestamp;
          const distance = targetTop - startPosition;
          const progress = timestamp - start;
          const duration = 1000;

          const distanceX = left - startXPosition;

          function easeInOutCubic(t, b, c, d) {
            let ts = t;
            ts /= d / 2;
            if (ts < 1) return c / 2 * ts * ts * ts + b;
            ts -= 2;
            return c / 2 * (ts * ts * ts + 2) + b;
          }

          // if (startDiv) {
          //   window.scrollTo(startPosition, easeInOutCubic(progress, startPosition, distance, duration));
          // } else {
            window.scrollTo(0, easeInOutCubic(progress, startPosition, distance, duration));
          // }
          anchorWrap.scrollTo(easeInOutCubic(progress, startXPosition, distanceX, duration), 0);
          if (progress < duration) window.requestAnimationFrame(step);
        }

        window.requestAnimationFrame(step);
      });
    });

    /*
      * Animate menu anchor on scroll
    */
    if (anchorsLink) {
      window.addEventListener('scroll', () => {
        const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;

        sectionAnchors.forEach((section) => {
          if (currentScrollPosition > section.offsetTop) {
            const link = `#${section.getAttribute('id')}`;
            anchorsLink.forEach((anchor) => {
              anchor.classList.remove('js-is-current');
              const anc = anchor.getAttribute('href');
              if (anc === link) {
                anchor.classList.add('js-is-current');
              }
            });
          }
        });

        /* Animation for responsive */
        anchorsLink.forEach((anchor) => {
          if (anchor.classList.contains('js-is-current')) {
            anchorWrap.scrollTo({ left: anchor.offsetLeft, top: 0, behavior: 'smooth' });
          }
        });
      });
    }
  }
};
