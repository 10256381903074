module.exports = function() {
  var nav_buttons = document.querySelectorAll('.nav-number');
  if (nav_buttons.length > 0) {
    var fields = document.querySelectorAll('.gfield');
    var submit = document.querySelector('.gform_button');
    const pages_number = nav_buttons.length;
    var page = 1;
    var target_page = page;

  //   // Construct pages index
    var field_page_number = 0;
    fields.forEach(function (field, index) {
      if ( field.classList.contains('gsection') ) {
        field_page_number++;
      } else {
        field.setAttribute('page_number', field_page_number);
      }
    });
  //

    //create previous/next & submit buttons
    createArrows();
    var arrow_prev = document.querySelector('.arrow-prev');
    var arrow_next = document.querySelector('.arrow-next');

    function createArrows(){
      var form = document.querySelector('.landing-form');
      var footer = document.querySelector('.gform_footer');
      var arrow_prev = document.createElement('div');
      var arrow_next = document.createElement('div');
      var next_text = document.createElement('span');
      var prev_text = document.createElement('span');
      arrow_prev.classList.add('arrow-prev', 'gform_previous_button');
      arrow_next.classList.add('arrow-next', 'gform_next_button');
      next_text.classList.add('text');
      next_text.innerHTML = 'Suivant';
      prev_text.classList.add('text');
      prev_text.innerHTML = 'Précédent';
      arrow_next.appendChild(next_text);
      arrow_prev.appendChild(prev_text);
      footer.insertBefore(arrow_next,submit);
      footer.insertBefore(arrow_prev, arrow_next);
    }

  // create top progress bar
    nav_buttons.forEach(nav_button => {
      nav_button.addEventListener('click', function(){
        target_page = nav_button.getAttribute('target_page');
        changePage(target_page, arrow_prev, arrow_next, nav_buttons, submit);
      });
    });

  // Activate arrows buttons
    arrow_prev.addEventListener('click', function(){
      page = parseInt(page)
      target_page = page-1;
      changePage(target_page, arrow_prev, arrow_next, nav_buttons, submit);
    });

    arrow_next.addEventListener('click', function(){
      page = parseInt(page)
      target_page = page+1;
      changePage(target_page, arrow_prev, arrow_next, nav_buttons, submit);
    });

  //display page elements
    changePage(page, arrow_prev, arrow_next, nav_buttons, submit);
    function changePage(target_page, arrow_prev, arrow_next, nav_buttons, submit) {
      fields.forEach(field => {
        if ( field.getAttribute('page_number') == target_page ) {
          field.classList.remove('js-is-hidden');
        } else {
          field.classList.add('js-is-hidden');
        }
      });

      page = target_page;

      if ( page == 1 ) {
        arrow_prev.classList.add('js-is-hidden');
      } else {
        arrow_prev.classList.remove('js-is-hidden');
      }

      //Make gform validation button visible
      if (page == pages_number) {
        arrow_next.classList.add('js-is-hidden');
        submit.classList.remove('js-is-hidden');
      } else {
        arrow_next.classList.remove('js-is-hidden');
        submit.classList.add('js-is-hidden');
      }

      nav_buttons.forEach(function (nav_button, i) {
        if ( (i + 1) <= page ) {
          nav_button.classList.add('is-active')
        } else {
          nav_button.classList.remove('is-active')
        }
      });

       return page;
    };
  }
}
