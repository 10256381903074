module.exports = function() {
  const icons = document.querySelectorAll('.lottie');

  if (icons) {
    icons.forEach((icon) => {
      if (icon.dataset.type === 'scroll') {
        setAnimation(false, icon);
        lottieScroll(icon);
      }

      if (icon.dataset.type === 'hover') {
        setAnimation(true, icon);
        lottieHover(icon);
      }
    });
  }
}

/*
* Initialisation des icône Lottie
* Permet de ne pas avoir de problème de load des animations
*/
function setAnimation (bool, icon) {
  const name = icon.dataset.name
  const slug = icon.dataset.lottie.replaceAll('_', '-');
  const url = getIconUrl(slug);

  const animation = lottie.loadAnimation({
    name: name,
    container: icon,
    renderer: 'svg',
    loop: bool,
    autoplay: false,
    path: url,
  });

  animation.addEventListener('DOMLoaded', () => {
    const shapes = icon.querySelectorAll('path');

    if (shapes) {
      shapes.forEach((shape) => {

        if (shape.getAttribute('fill') === 'rgb(8,168,138)') {
          shape.setAttribute('fill', 'rgb(234,28,48)');
        }

        if (shape.getAttribute('stroke') === 'rgb(8,168,138)') {
          shape.setAttribute('stroke', 'rgb(234,28,48)');
        }
      });
    }
  });
}

/**
 * Return l'url de stockage des icons
 * @param {string} slug 
 * @returns 
 */
function getIconUrl(slug) {
  let url = '';
  const location = window.location;
  if (window.location.hostname === 'localhost') {
    url = location.protocol + "//" + location.host + "/" + location.pathname.split('/')[1];
  } else {
    url = location.origin;
  }
  return url + '/wp-content/uploads/icons/' + slug + '.json';
}


/*
* Animation au scroll
*/
function lottieScroll(icon) {
    const imageObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          lottie.play(icon.dataset.name);
        } else {
          lottie.stop(icon.dataset.name);
        }
      });
    });
    imageObserver.observe(icon);
}

/*
* Animation au Hover
*/
function lottieHover(icon) {
  icon.addEventListener('mouseenter', () => {
    lottie.setSpeed(0.8);
    lottie.play(icon.dataset.name);

    const popin = icon.querySelector('.c-button-icon__hover');
    if(popin !== null) {
      popin.classList.add('js-is-visible');
    }
  });

  icon.addEventListener('mouseleave', () => {
    lottie.stop(icon.dataset.name);

    const popin = icon.querySelector('.c-button-icon__hover');
    if(popin !== null) {
      popin.classList.remove('js-is-visible');
    }
  });
}
