module.exports = function () {

  /*
    * Menu nav
  */
  const anchors = document.querySelectorAll('.content_template_nav__menu li');
  const anchorsLink = document.querySelectorAll('.content_template_nav__menu li a');
  const marquise = document.querySelector('.marquise');
  if (anchors && marquise) {
    const marquiseTop = marquise.offsetTop;
    const wrapperTop = marquiseTop + 70; // padding de la div enfant .container


    /*
      *stick manu bar to sticky header
    */
    const header = document.querySelector('#header');
    var headerBottom = $(header).outerHeight() - 14; // moins les paddings de header
    // const menuWrap = document.querySelector('.content_template_nav');
    // if (menuWrap) {
    //     menuWrap.style.top = headerBottom + 'px';
    // }


    if (anchors) {
      anchors.forEach((anchor) => {
        /*
          * Animate scrollTo on menu anchor item click
        */
        anchor.addEventListener('click', (event) => {
          event.preventDefault();

          // add class current to menu anchor item clicked
          setTimeout(() => {
            // reset class current on all menu anchors item
            anchorsLink.forEach((link) => {
              link.classList.remove('js-is-current');
            });

            event.target.classList.add('js-is-current');
          }, 500);

          // get href and get which section has this id
          const target = event.target.getAttribute('href');
          const top = document.querySelector(target).offsetTop - 200;
          const left = event.target.offsetLeft;
          const targetTop = top + parseInt(wrapperTop);
          const startPosition = window.pageYOffset;
          const startXPosition = window.pageXOffset;

          /* Animation scroll */
          const anchorWrap = document.querySelector('.content_template_nav__menu');
          let start = null;

          function step(timestamp) {
            if (!start) start = timestamp;
            const distance = targetTop - startPosition;
            const progress = timestamp - start;
            const duration = 1000;

            const distanceX = left - startXPosition;

            function easeInOutCubic(t, b, c, d) {
              let ts = t;
              ts /= d / 2;
              if (ts < 1) return c / 2 * ts * ts * ts + b;
              ts -= 2;
              return c / 2 * (ts * ts * ts + 2) + b;
            }

            window.scrollTo(0, easeInOutCubic(progress, startPosition, distance, duration));
            anchorWrap.scrollTo(easeInOutCubic(progress, startXPosition, distanceX, duration), 0);
            if (progress < duration) window.requestAnimationFrame(step);
          }

          window.requestAnimationFrame(step);
        });
      });
    }

    /*
      * Mise en place de l'animation du menu des catégories de formation
    */
      const formationsWrapper = document.querySelectorAll('.content_template_content__wrapper');
      const menuItems = document.querySelectorAll('.content_template_nav__menu li a');

      if (formationsWrapper) {
        /*
          * si on scrolle sur la section en cours : on met à jour le menu sur l'item current
        */
        window.addEventListener('scroll', () => {
          const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;

          formationsWrapper.forEach((section) => {
            if (currentScrollPosition > section.offsetTop + parseInt(wrapperTop) - 250) {
              const link = `#${section.getAttribute('id')}`;
              menuItems.forEach((anchor) => {
                anchor.classList.remove('js-is-current');
                const anc = anchor.getAttribute('href');
                if (anc === link) {
                  anchor.classList.add('js-is-current');
                }
              });
            }
          });

          /* Animation for responsive */
          menuItems.forEach((anchor) => {
            if (anchor.classList.contains('js-is-current')) {
              setTimeout(() => {
                menuWrap.scrollTo({ left: anchor.offsetLeft, top: 0, behavior: 'smooth' });
              }, 500)
            }
          });
        });
      }
    }
  };
