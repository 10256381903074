var firstPixel = require('./core/core.js');
var lottie     = require('./functions/lottie.js');
var slider     = require('./functions/sectionClientSlider.js');
var sliderHome = require('./functions/slider-home.js');
var sliderSectionCols = require('./functions/sliderSectionCols.js');
var sliderPartners     = require('./functions/sliderPartners.js');
var sliderPartnersOnePage     = require('./functions/sliderPartnersOnePage.js');
var header     = require('./functions/header.js');
var menuFooterResponsive  = require('./functions/menuFooterResponsive.js');
var modal      = require('./functions/modal.js');
var smallVideo = require('./functions/smallVideo.js');
var animRound  = require('./functions/animRound.js');
var review 		 = require('./functions/review.js');
var backToTop  = require('./functions/backToTop.js');
var menuAnchor = require('./functions/menuAnchor.js');
var blog       = require('./functions/blog.js');
var readbar    = require('./functions/readbar.js');
var comments    = require('./functions/comments.js');
var authorPagination  = require('./functions/authorPagination.js');
var stickyFormation  = require('./functions/stickyFormation.js');
var landingFormPagination = require('./functions/landingFormPagination.js');
var contenuTemplateNav = require('./functions/contenuTemplateNav.js');
var modalCatFormations = require('./functions/modalCatFormations.js');
var onePageTesti     = require('./functions/onePageTesti.js');
var onePageTabs     = require('./functions/onePageTabs.js');
var onePageMenuResp     = require('./functions/onePageMenuResp.js');
var sliderConsultant     = require('./functions/sliderConsultant.js');
var slideTestimony     = require('./functions/slideTestimony.js');
var hubspot     = require('./functions/hubspot.js');
var simulation     = require('./functions/faqSimulation.js');
var didomi     = require('./functions/didomiLink.js');
// var animation  = require('./functions/animation.js');

jQuery(document).ready(function($){
  simulation();
	firstPixel();
	lottie();
	slider();
	sliderHome();
	sliderPartners();
	sliderPartnersOnePage();
	header();
	menuFooterResponsive();
	modal();
	smallVideo();
	animRound();
	review();
	backToTop();
	menuAnchor();
	blog();
	readbar();
	comments();
	authorPagination();
	stickyFormation();
	landingFormPagination();
	contenuTemplateNav();
	modalCatFormations();
	sliderSectionCols();
	onePageTesti();
	onePageTabs();
	onePageMenuResp();
	sliderConsultant();
	slideTestimony();
	hubspot();
  didomi();
});
