module.exports = function() {
  /*
    * Event on back to top button
  */

  const backToTopButton = document.querySelector('.c-button-top');

  if (backToTopButton) {
    /* On click */
    backToTopButton.addEventListener('click', () => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    });

    /* Window Scroll */
    /* To make it visible when scrolling */
    window.addEventListener('scroll', () =>  {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition > 250) {
        backToTopButton.classList.add('js-is-visible');
      } else {
        backToTopButton.classList.remove('js-is-visible');
      }
    });
  }
}
