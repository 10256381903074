module.exports = function () {
  /*
    * Affichage du bouton sticky sur page de catégorie de formation
  */

  const didomiLink = document.querySelector('.didomiLink');
  if (didomiLink !== undefined) {
    didomiLink.addEventListener('click', function (e) {
      e.preventDefault()
      Didomi.preferences.show()
    })
  }
}
