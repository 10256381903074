module.exports = function () {
  /*
    * Affichage petite video quand contenu flexible video
    * est présent dans les contenus flexible
    * Lorsque l'on scroll en dessous elle apparait en fixed en bas à droite  
  */
  const smallVideo =  document.querySelectorAll('.c-bloc-video');
  
  if (smallVideo) {
    smallVideo.forEach((video) => {
      window.addEventListener('scroll', () => {
        const blocVideoTop = video.getBoundingClientRect().top;
        const miniVideo = video.querySelector('.small-video');
        const handler = parseInt(video.dataset.handler, 10);

        if (blocVideoTop <= 0) {
          if (handler === 0) {
            video.setAttribute('data-handler', 1);
            miniVideo.classList.remove('js-is-hidden');
          }
        }
        if (blocVideoTop > 0) {
          video.setAttribute('data-handler', 0);
          miniVideo.classList.add('js-is-hidden');
        }
      });
    });
  }
};
