module.exports = function () {
  /*
    * Affichage du bouton sticky sur page de catégorie de formation
  */
  const buttonFixed = document.querySelector('.page-template-page-cat-formation .c-button-fixed');
  
  if (buttonFixed) {
    window.addEventListener('scroll', () => {
      const categoryNav = document.querySelector('.formation-cat-nav');
      const categoryNavTop = categoryNav.getBoundingClientRect().top;
      const handler = parseInt(buttonFixed.dataset.handler, 10);

      if (categoryNavTop <= 105) {
        if (handler === 0) {
          buttonFixed.setAttribute('data-handler', 1);
          buttonFixed.classList.add('js-is-hidden');
        }
      }
      if (categoryNavTop > 105) {
        buttonFixed.setAttribute('data-handler', 0);
        buttonFixed.classList.remove('js-is-hidden');
      }
    });
  }

 

  /*
    * Menu nav
  */
  const anchors = document.querySelectorAll('.formation-cat-nav__menu li');
  const anchorsLink = document.querySelectorAll('.formation-cat-nav__menu li a');

  if (anchors) {
    anchors.forEach((anchor) => {
      /*
        * Animate scrollTo on menu anchor item click
      */
      anchor.addEventListener('click', (event) => {
        event.preventDefault();

        // add class current to menu anchor item clicked
        setTimeout(() => {
          // reset class current on all menu anchors item
          anchorsLink.forEach((link) => {
            link.classList.remove('js-is-current');
          });

          event.target.classList.add('js-is-current');
        }, 500);

        // get href and get which section has this id 
        const target = event.target.getAttribute('href');
        const contentIn = 50;
        const top = document.querySelector(target).offsetTop - 100;
        const left = event.target.offsetLeft;
        const targetTop = top + parseInt(contentIn, 10);
        const startPosition = window.pageYOffset;
        const startXPosition = window.pageXOffset;

        /* Animation scroll */
        const anchorWrap = document.querySelector('.formation-cat-nav__menu');
        let start = null;

        function step(timestamp) {
          if (!start) start = timestamp;
          const distance = targetTop - startPosition;
          const progress = timestamp - start;
          const duration = 1000;

          const distanceX = left - startXPosition;

          function easeInOutCubic(t, b, c, d) {
            let ts = t;
            ts /= d / 2;
            if (ts < 1) return c / 2 * ts * ts * ts + b;
            ts -= 2;
            return c / 2 * (ts * ts * ts + 2) + b;
          }

          window.scrollTo(0, easeInOutCubic(progress, startPosition, distance, duration));
          anchorWrap.scrollTo(easeInOutCubic(progress, startXPosition, distanceX, duration), 0);
          if (progress < duration) window.requestAnimationFrame(step);
        }

        window.requestAnimationFrame(step);
      });
    });
  }

  /*
    * Mise en place de l'animation du menu des catégories de formation
  */
    const formationsWrapper = document.querySelectorAll('.formation-cat-content__wrapper');
    const menuItems = document.querySelectorAll('.formation-cat-nav__menu li a');
    const menuWrap = document.querySelector('.formation-cat-nav');
  
    if (formationsWrapper) {
      /* 
        * si on scrolle sur la section en cours : on met à jour le menu sur l'item current
      */
      window.addEventListener('scroll', () => {
        const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
  
        formationsWrapper.forEach((section) => {
          if (currentScrollPosition > section.offsetTop - 100) {
            const link = `#${section.getAttribute('id')}`;
            menuItems.forEach((anchor) => {
              anchor.classList.remove('js-is-current');
              const anc = anchor.getAttribute('href');
              if (anc === link) {
                anchor.classList.add('js-is-current');
              }
            });
          }
        });
  
        /* Animation for responsive */
        menuItems.forEach((anchor) => {
          if (anchor.classList.contains('js-is-current')) {
            setTimeout(() => {
              menuWrap.scrollTo({ left: anchor.offsetLeft, top: 0, behavior: 'smooth' });
            }, 500)
          }
        });
      });
    }
  };
