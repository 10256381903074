module.exports = function () {
  /*
    * Affichage du bouton sticky sur page de catégorie de formation
  */

  const questions = document.querySelectorAll('.simulationFAQ__faq__question');
  const answers = document.querySelectorAll('.simulationFAQ__faq__answer');
  if (questions.length > 0) {

    answers.forEach(el => {
      el.setAttribute('data-height', el.offsetHeight+'px')
      el.style.height = '0'
    })

    questions.forEach( (el, index) => {
      el.addEventListener('click', () => {
        questions.forEach((question, index2) => {
          if (question.classList.contains('is-current') && index !== index2) {
            question.classList.remove('is-current')
          }
        })
        questions[index].classList.toggle('is-current')

        answers.forEach((answer, index3) => {
          if ( index !== index3 ) {
            answer.style.height = '0'
          } else {
            if (questions[index].classList.contains('is-current')){
              answer.style.height = answer.getAttribute('data-height')
            } else {
              answer.style.height = '0'
            }
          }
        })
      });
    })
  }
};
