module.exports = function() {
    let top = 0;
    let marquiseInfo = false;
    let readingPercent = 0;

    window.addEventListener('scroll', () => {
        const marquise = document.querySelector('.container_marquise');
        const readbarElt = document.querySelector('.readBar');

        if (readbarElt) {
            const reading = readbarElt.querySelector('.reading');
            const readBarHeight = readbarElt.getBoundingClientRect().height;

            marquiseInfo = marquise.getBoundingClientRect();


            if (marquiseInfo.y < 20) {
                top = Math.min(
                    20 - parseInt(marquiseInfo.y, 10),
                    parseInt(marquiseInfo.height, 10) - parseInt(readBarHeight, 10) - 80,
                );
                top = top
            } else {
                top = 0;
            }

            readbarElt.style.top = top+ 'px';

            readingPercent = 2 + (top / (parseInt(marquiseInfo.height, 10) - parseInt(readBarHeight, 10)) * 100);

            reading.style.height = readingPercent+ '%';
        }
    });
}