module.exports = function() {
    const paginationAuthor = document.querySelector('.c-pagination-author');

    if (paginationAuthor) {
        const currentPage = parseInt(paginationAuthor.dataset.currentPage);
        const slugAuthor = paginationAuthor.dataset.slugAuthor;
        const itemsPagination = paginationAuthor.querySelectorAll('.c-pagination-author__item');
        hoverPagination(currentPage);

        itemsPagination.forEach((itemPagination) => {
            itemPagination.addEventListener('click', (e) => {
                e.preventDefault();

                const pageSelected = e.target.dataset.page;
                paginationAuthor.dataset.currentPage = pageSelected;

                const newCurrentPage = parseInt(paginationAuthor.dataset.currentPage);
                
                hoverPagination(newCurrentPage);

                const xhr = new XMLHttpRequest();
                xhr.open('POST', ajaxurl, true);
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                const params = `action=paginationAuthor&paged=${pageSelected}&slug=${slugAuthor}`;
                
                xhr.send(params);

                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4 && xhr.status === 200) {
                        const blocAjax = document.querySelector('.bloc-ajax');
                        blocAjax.innerHTML = xhr.response;
                    }
                };
                
            });
        });
    }
}

function hoverPagination(currentPage)
{
    const paginationAuthor = document.querySelector('.c-pagination-author');
    const itemsPagination = paginationAuthor.querySelectorAll('.c-pagination-author__item');

    itemsPagination.forEach((itemPagination) => {
        if (parseInt(itemPagination.dataset.page) === currentPage) {
            itemPagination.classList.add('js-is-current')
        } else {
            if (itemPagination.classList.contains('js-is-current')) {
                itemPagination.classList.remove('js-is-current')
            }
        }
    });
}