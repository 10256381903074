module.exports = function() {
  const blocSlider = document.querySelector('.c-consulting__slider')

  if (blocSlider) {
    activateTabs(blocSlider)

    const slider = new Glide(blocSlider, {
      startAt: 0,
      animationDuration: 800,
    })

    slider.on(['mount.after', 'run'], () => {
      const currentIndex = slider.index;
      blocSlider.dataset.currentSlide = currentIndex;
    });

    slider.mount({
      'Sliderheight': Sliderheight()
    });
  }
}

/**
 * Active la transtion des onglets
 * @param {*} blocSlider 
 */
function activateTabs(blocSlider) {
  let currentSlide = 0;
  const tabs = document.querySelectorAll('.c-consulting__slider__nav__items')
  const controller = document.querySelector('.nav-controller')

  if (tabs) {
    tabs.forEach((tab) => {
      tab.addEventListener('click', () => {
        if (tab.dataset.glideDir === "=1") {
          currentSlide = 1
          tabs[0].classList.remove('js-current')
        } else {
          currentSlide = 0
          tabs[1].classList.remove('js-current')
        }
        tab.classList.add('js-current')

        if (currentSlide === 0) {
          controller.classList.add('js-start')
          controller.classList.remove('js-end')
        } else {
          controller.classList.add('js-end')
          controller.classList.remove('js-start')
        }

        blocSlider.dataset.currentSlide = currentSlide;
      })
    });
  }
}

function Sliderheight() {
  const Sliderheight = function (Glide, Components, Events) {
    const AUTOHEIGHT = {
      mount () {
        Components.Html.track.style.transition = 'height 0.2s ease-in-out';
        AUTOHEIGHT.set();
      },
      set(){
        var heightBase = `${Components.Html.slides[Glide.index].childNodes[1].offsetHeight}px`;
        var finalHeight = "calc("+ heightBase +" + 50px)";

        Components.Html.track.style.height = finalHeight;
      }
    }
    Events.on(['run', 'resize'], () => {
      AUTOHEIGHT.set()
    })
    return AUTOHEIGHT
  }
  return Sliderheight;
}

