module.exports = function() {
  const btns = document.querySelectorAll('.c-section-testimony .bullet');
  const contents = document.querySelectorAll('.c-section-testimony .content');

  if (btns) {
    btns.forEach((btn, i) => {
      btn.addEventListener('click', function() {
        if (contents) {
          contents.forEach((content, j) => {
            if (j !== i) {
              content.classList.remove('animateLeft');
              content.classList.add('no-opacity-animation');
            } else {
              content.classList.add('animateLeft');
              content.classList.remove('no-opacity-animation');
            }
          });
        }
        if (btns) {
          btns.forEach((v) => {
            v.classList.remove('bullet--active');
          });
        }
        btn.classList.add('bullet--active');
      });
    });
  }
}
