module.exports = function () {
  /*
    * Animation des ronds
    * quand data-translate = true
    * data-strength utilisé pour modifier la vitesse de déplacement des ronds
  */
  const rounds =  document.querySelectorAll('.round');
  const height = window.innerHeight;
  
  if (rounds) {
    rounds.forEach((round) => {
      const willTranslate = round.dataset.translate;
      const strength = round.dataset.strength;
      const parent = round.parentElement;
      const parallaxLimit = parent.offsetTop + parent.offsetHeight;
      
      
      if(willTranslate) {
        window.addEventListener('scroll', () => {
          if (window.scrollY <= parallaxLimit);
          const scrollRatio = window.scrollY / height;
          const translateY = -scrollRatio * strength * 100;

          round.style.transform = `translateY( ${translateY}%)`;
        });
      }
    });
  }
};
