module.exports = function() {
    const forms = document.querySelectorAll('.hbspt-form');

    if (forms) {
        forms.forEach((form) => {
            window.addEventListener('load', () => {
                const tjm = form.querySelector('.hs_tjm');

                if (tjm) {
                    tjm.addEventListener('input', () => {
                        addFlagInOptions(form);
                    })
                } else {
                    addFlagInOptions(form);
                }
            });
        });
    }
}

function addFlagInOptions(form) {
    const phoneInput = form.querySelector('.hs_phone');
    if (phoneInput) {
      const options = phoneInput.querySelectorAll('select option');
      if (options) {
          options.forEach((option) => {
              const flag = getFlag(option.value);
              const name = option.innerHTML;
  
              option.innerHTML = flag + ' ' + name;
          });
      }
    }
}

/**
 * Transform le code pays en drapeau
 */
function getFlag(code) {
    if (code !== undefined) {
        return code
            .split('')
            .map(letter => letter.charCodeAt(0) % 32 + 0x1F1E5)
            .map(n => String.fromCodePoint(n))
            .join('');
    }
    return '';
}
