function modal() {
  const buttonPopin = document.querySelectorAll('.button-popin');

  if (buttonPopin) {
    buttonPopin.forEach((button) => {
      /*
        * INIT
      */
      const linkContainer = button.parentNode;
      const modal = button.parentNode.querySelector('.c-modal-container');
      const close = modal.querySelector('.c-modal-close');
      const overlay = modal.querySelector('.overlay');


      /* 
        * OPEN MODAL
      */
      button.addEventListener('click', (e) => {
        e.preventDefault();
        modal.classList.toggle('js-is-open');
      });

      
      /* 
        * CLOSE MODAL
      */
      close.addEventListener('click', () => {
        modal.classList.remove('js-is-open');
      });

      overlay.addEventListener('click', () => {
        modal.classList.remove('js-is-open');
      });
    });
  }
}

module.exports = modal;
