module.exports = function() {
    const currentDiv = document.querySelector('.c-menu-onepage-responsive .current-li');
    if (currentDiv) {
        const menu = document.querySelector('.c-menu-onepage-responsive__menu');
        // afficher le sous menu au clic sur current-nav
        currentDiv.addEventListener('click', () => {
            menu.classList.toggle('is-visible')
            currentDiv.classList.toggle('is-visible')
        });
    }
}
