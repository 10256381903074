module.exports = function () {
    const slidePartners = document.querySelector('.c-slider-partners');
    const config = {
        type: 'carousel',
        startAt: 0,
        animationDuration: 800,
        perView: 5,
        autoplay: 3000,
        breakpoints: {
            1200: {
                perView: 4,
            },
            800: {
                perView: 3,
            },
            550: {
                perView: 2,
            },
        }
    };

    if (slidePartners) {
        new Glide(slidePartners, config).mount();
    }
}
